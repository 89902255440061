export function dateToString(input: Date): string{
    const date = createDate(input);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function daysBetweenDates(start: string | Date, end: string | Date) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeStartDate = startDate.getTime();
    const timeEndDate = endDate.getTime();

    const millisecondsDiff = Math.abs(timeStartDate - timeEndDate);
    return Math.floor(millisecondsDiff / (1000 * 60 * 60 * 24)) + 1;
}

export function rangeInRangeDates(start: string | Date, end:string | Date, rangeFrom: string | Date, rangeTo: string | Date) {
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    const rangeFromTime = new Date(rangeFrom).getTime();
    const rangeToTime = new Date(rangeTo).getTime();
    return startTime >= rangeFromTime && endTime <= rangeToTime;
}

export function rangeOnRangeDates(start: string | Date, end:string | Date, rangeFrom: string | Date, rangeTo: string | Date) {
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    const rangeFromTime = new Date(rangeFrom).getTime();
    const rangeToTime = new Date(rangeTo).getTime();
    return !(rangeFromTime > endTime || rangeToTime < startTime);
}

export function dayInRangeDates(day: string, rangeFrom: string, rangeTo: string) {
    const dayTime = new Date(day).getTime();
    const rangeFromTime = new Date(rangeFrom).getTime();
    const rangeToTime = new Date(rangeTo).getTime();
    return dayTime >= rangeFromTime && dayTime <= rangeToTime;
}

export function getCommonDateRange(startDate1Str: string | Date, endDate1Str: string | Date, startDate2Str: string | Date, endDate2Str: string | Date): { start: Date; end: Date } | null {
    const range1Start = createDate(startDate1Str);
    const range1End = createDate(endDate1Str);
    const  range2Start = createDate(startDate2Str);
    const  range2End = createDate(endDate2Str);
    const commonStart = range1Start > range2Start ? range1Start : range2Start;
    const commonEnd = range1End < range2End ? range1End : range2End;

    if (commonStart <= commonEnd) {
        return { start: commonStart, end: commonEnd };
    } else {
        return null;
    }
}

export function getCommonDays(startDate1Str: string | Date, endDate1Str: string | Date, startDate2Str: string | Date, endDate2Str: string | Date): number {
    const startDate1 = new Date(startDate1Str);
    const endDate1 = new Date(endDate1Str);
    const startDate2 = new Date(startDate2Str);
    const endDate2 = new Date(endDate2Str);

    const minStartDate = startDate1 < startDate2 ? startDate2 : startDate1;
    const maxEndDate = endDate1 > endDate2 ? endDate2 : endDate1;

    const timeDiff = maxEndDate.getTime() - minStartDate.getTime();

    const daysInCommon = Math.floor(timeDiff / (1000 * 3600 * 24));

    return daysInCommon + 1;
}

export function formatDateDecorator(dateString: string, separator?: string): string {
    const date = new Date(dateString + 'T00:00:00Z');

    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return separator? `${day}${separator}${month}${separator}${year}` : `${day}-${month}-${year}`;
}

export function getLastDayMonth(): Date {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth() + 1, 0);
}

const pad = (num: number): string => {
    if(num < 10){
        return `0${num}`;
    }
    return `${num}`;
}

export const getStringDate = (input: Date): string => {
    return `${input.getFullYear()}-${pad(input.getMonth() + 1)}-${pad(input.getDate())}`;
}

const truncateTimeToDateString = (input: string) => {
    const truncatedString = input.split("T");
    return truncatedString[0] ?? input;
}

export const createDate = (input: string | Date) => {
    const parsedStringInput = typeof input === 'string' ? truncateTimeToDateString(input) : getStringDate(input);
    const partialsInput: number[] = parsedStringInput.split("-").map(part => parseInt(part));
    if(partialsInput[0] && partialsInput[1] && partialsInput[2]){
        return new Date(partialsInput[0], partialsInput[1] - 1, partialsInput[2]);
    }
    return new Date(input);
}