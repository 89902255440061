import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {appConfig} from "../../../../config";

const API_URL = appConfig.API_URL;

interface ProductOccupationParams {
    from: string | undefined;
    to: string | undefined;
    applicationId: string;
}

interface ProductStatistics {
    from: string | undefined;
    to: string | undefined;
    applicationId: string;
}

export const resumeApi = createApi({
    reducerPath: "ResumeApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers: Headers) => {
            const APP_TOKEN = "app_token";
            const token = localStorage.getItem(APP_TOKEN);

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                headers.set("Content-Type", "application/json");
            }

            return headers;
        },
        credentials: "include",
    }),
    tagTypes: ['ProductOccupation', 'ProductSales', 'ProductHistoryOccupation', 'SalesSegmentation', 'CustomerOrigins', 'PaidSales'],
    endpoints: (builder) => ({
        getIncomes: builder.query<any, ProductOccupationParams>({
            query: ({from, to, applicationId}) => `statistics/incomes?from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: (result, error, params) => ['ProductOccupation']
        }),
        getProductOccupation: builder.query<any, ProductOccupationParams>({
            query: ({from, to, applicationId}) => `statistics/product-occupation?from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: (result, error, params) => ['ProductOccupation']
        }),
        getProductSales: builder.query<any, ProductStatistics>({
            query: ({from, to, applicationId}) => `statistics/products-sales?from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: ['ProductSales']
        }),
        getProductHistoryOccupation: builder.query<any, ProductStatistics>({
            query: ({from, to, applicationId}) => `statistics/products-history-occupation?from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: ['ProductHistoryOccupation']
        }),
        getSalesSegmentation: builder.query<any, ProductStatistics>({
            query: ({from, to, applicationId}) => `statistics/sales-segmentation?from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: (result, error, params) => ['SalesSegmentation']
        }),
        getPaidSales: builder.query<any, ProductStatistics>({
            query: ({from, to, applicationId}) => `statistics/paid-sales?from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: (result, error, params) => ['PaidSales']
        }),
        getCustomerOrigins: builder.query<any, ProductStatistics>({
            query: ({from, to, applicationId}) => `statistics/customer-origins?from=${from}&to=${to}&applicationId=${applicationId ?? appConfig.APPLICATION_ID}`,
            providesTags: (result, error, params) => ['CustomerOrigins']
        }),
    }),
});

export const { useGetProductOccupationQuery, useGetProductSalesQuery, useGetProductHistoryOccupationQuery, useGetIncomesQuery, useGetSalesSegmentationQuery, useGetPaidSalesQuery, useGetCustomerOriginsQuery } = resumeApi;
