import {EndpointSeason, Season} from "../domain/Season.model";
import {createAdapterResort} from "../../resort/adapters/resort.adapter";

export const createAdapterSeason = (season: EndpointSeason) => {
    const formattedSeason: Season = {
        id: season.id,
        start: season.start,
        end: season.end,
        name: season.name,
        description: season.description,
        resort: season.resort ? createAdapterResort(season.resort) : undefined,
        active: season.active
    };
    return formattedSeason;
}