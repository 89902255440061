import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {appConfig} from "../../../config";
import {TableOptions} from "../../../shared-kernel/components/Table";
import { EndpointProductInventory, ProductInventory, ProductInventoryDataProps } from "../domain/ProductInventory.model";
import { createAdapterProductInventory } from "../adapters/productInventory.adapter";
import { ResortApplication } from "../../../resort/domain/Resort.model";


const API_URL = appConfig.API_URL;

const PRODUCTS_INVENTORY_TAG = "ProductsInventory";

export const productInventoryApi = createApi({
    reducerPath: "ProductInventoryApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,
        prepareHeaders: (headers: Headers) => {
            const APP_TOKEN = "app_token";
            const token = localStorage.getItem(APP_TOKEN);

            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                headers.set("Content-Type", "application/json");
            }

            return headers;
        },
        credentials: "include",
    }),
    tagTypes: [PRODUCTS_INVENTORY_TAG],
    endpoints: (builder) => ({
        getProductsInventory: builder.query<ProductInventoryDataProps, TableOptions<ProductInventory> & ResortApplication | undefined>({
            query: (tableOptions) =>{
                const applicationId = tableOptions?.applicationId ?? appConfig.APPLICATION_ID;
                let queryParams: string = `applicationId=${applicationId}`;
                if(tableOptions !== undefined && tableOptions.page){
                    const page = tableOptions.page - 1;
                    queryParams += `&page=${page}`;
                }
                if(tableOptions !== undefined && tableOptions.pageSize){
                    queryParams += `&pageSize=${tableOptions.pageSize}`;
                }
                if(tableOptions !== undefined && tableOptions.order){
                    const parsedOrder = JSON.stringify({
                        field: tableOptions.order.field,
                        order: tableOptions.order.order
                    })
                    queryParams += `&order=${parsedOrder}`;
                }
                if(tableOptions !== undefined && tableOptions.search){
                    queryParams += `&search=${tableOptions.search}`;
                }
                return `productsinventory/list${queryParams !== '' ? `?${queryParams}` : ''}`
            }
            ,
            providesTags: [PRODUCTS_INVENTORY_TAG],
            transformResponse: (response: any) => {
                return {
                    data: response.data.map((productInventory: EndpointProductInventory) => createAdapterProductInventory(productInventory)),
                    page: response.page,
                    total: response.total,
                };
            }
        }),
        updateProductInventory: builder.mutation<any, any>({
            query: (data: any) => {
                return {
                    url: `productsinventory/${data.id}`,
                    method: "PUT",
                    body: {
                        ...data,
                        applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                    }
                }
            },
            invalidatesTags: [PRODUCTS_INVENTORY_TAG],
        }),
        getProductInventoryById: builder.query<EndpointProductInventory, string | undefined>({
            query: (id) => `productsinventory/${id}`,
            providesTags: (result, error, id) => [{ type: 'ProductsInventory', id: id }],
            transformResponse: (response: EndpointProductInventory) => createAdapterProductInventory(response),
        }),
        createProductInventory: builder.mutation<any, any>({
            query: (data) => ({
                url: 'productsinventory/',
                method: "POST",
                body: {
                    ...data,
                    applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                },
            }),
            invalidatesTags: [PRODUCTS_INVENTORY_TAG],
        }),
        createMassiveProductInventory: builder.mutation<any, any>({
            query: (data) => ({
                url: 'productsinventory/massive',
                method: "POST",
                body: {
                    ...data,
                    applicationId: data.applicationId ?? appConfig.APPLICATION_ID
                },
            }),
            invalidatesTags: [PRODUCTS_INVENTORY_TAG],
        }),
        deleteProductInventory: builder.mutation<any, any>({
            query: (id) => ({
                url: `productsinventory/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [PRODUCTS_INVENTORY_TAG]
        }),
    }),
});

export const { useGetProductInventoryByIdQuery, useGetProductsInventoryQuery, useCreateProductInventoryMutation, useUpdateProductInventoryMutation, useDeleteProductInventoryMutation, useCreateMassiveProductInventoryMutation } = productInventoryApi;
