import styled from "styled-components";

interface StyledDropDownMenuProps {
  openDropMenu?: boolean;
}

export const StyledDropDownMenu = styled.ul<StyledDropDownMenuProps>`
  position: absolute;
  top: 74px;
  right: 0;
  width: 250px;
  max-height: ${(props) => (props.openDropMenu ? "100vh" : "0px")};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  overflow-y: hidden;
  font-size: 1rem;
  background-color: ${(props) => props.theme.layout.sidebar.background};
  color: ${(props) => props.theme.layout.sidebar.textColor};
  transition: all 300ms ease-in-out;
  box-shadow: 0 2px 4px  ${(props)=> `${props.theme.layout.sidebar.textColor}40`};
  a {
        text-decoration: none;
    }
`;
