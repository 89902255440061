import { LOCALES } from "../locales";

const spanishLocale = {
  [LOCALES.SPANISH]: {
    hello: "Hola!",
    username: "Nombre de usuario",
    password: "Contraseña",
    "sign in": "Iniciar sesión",
    login: "Acceder",
    "is required": "es requerido",
    exit: "Salir",
    dashboard: "Resumen",
    users: "Usuarios",
    total: "Total",
    page: "Página",
    show: "Mostrar",
    records: "registros",
    firstname: "Nombres",
    lastname: "Apellidos",
    of: "de",
    filters: "Filtros",
    contains: "Contiene",
    doesNotContain: "No contiene",
    startsWith: "Empieza por",
    endsWith: "Termina con",
    equals: "Igual a",
    notEquals: "No es igual a",
    greaterThan: "Mayor que",
    greaterThanOrEqual: "Mayor o igual que",
    lessThan: "Menor que",
    lessThanOrEqual: "Menor o igual que",
    after: "Después de",
    before: "Antes de",
    between: "Entre",
    operator: "Operador",
    value: "Valor",
    and: "y",
    home: "Inicio",
    "there was an error loading the menus": "Hubo un error cargando los menús",
    settings: "Configuracion",
    profile: "Perfil",
    "public avatar": "Avatar Público",
    "change anything related with your profile picture":
      "Cambia todo lo relacionado con tu imagen de perfil",
    "upload avatar": "Sube un avatar",
    "the maximum file size allowed is 200kb":
      "El tamaño máximo permitido es de 200 KB",
    "main settings": "Configuración principal",
    "change anything related with your profile data":
      "Cambia todo lo relacionado con los datos de tu perfil",
    "enter your first name": "Ingresa tus nombres",
    "enter your last name": "Ingresa tus apellidos",
    "enter your username, so people you know can recognize you":
      "Ingresa tu nombre de usuario para que las personas que conoces puedan reconocerte",
    "this email will be displayed on your public profile":
      "Este correo electrónico se mostrará en tu perfil público",
    update: "Actualizar",
    theme: "Tema",
    language: "Idioma",
    "change to your prefered language": "Cambia a tu idioma de preferencia",
    "change the main theme": "Cambia el tema principal",
    email: "Correo Electrónico",
    phone: "Teléfono",
    "stock administration": "Administración de zonas",
    "products administration": "Administración de productos",
    "tags administration": "Administración de etiquetas",
    "trademark administration": "Administración de marcas",
    "categories administration": "Administración de categorías",
    "edit stock": "Editar zona",
    "new stock": "Nueva zona",
    "edit product": "Editar producto",
    "new product": "Nuevo producto",
    "edit tag": "Editar etiqueta",
    "new tag": "Nueva etiqueta",
    "new trademark": "Nueva marca",
    "edit trademark": "Editar marca",
    "new category": "Nueva categoria",
    "edit category": "Editar categoria",
    timezone: "Huso Horario",
    "change timezone": "Cambiar huso horario",
    add: "Agregar",
    edit: "Editar",
    delete: "Eliminar",
    search: "Buscar",
    "this is your main password": "Esta es tu contraseña principal",
    "*": "*",
    "there was an error loading the data": "Hubo un error cargando los datos",
    "there was an error loading the user data":
      "Hubo un error cargando los datos del usuario",
    name: "Nombre",
    "change anything related with your permissions data":
      "Cambia todo lo relacionado con los datos de tu permiso",
    "enter permission name": "Ingresa el nombre del permiso",
    "Permission not found": "Permiso no encontrado",
    "User not found": "Usuario no encontrado",
    "change anything related with your roles data":
      "Cambia todo lo relacionado con los datos de tu rol",
    "Role not found": "Rol no encontrado",
    "enter role name": "Ingresa el nombre del rol",
    offset: "Diferencia",
    none: "Seleccione",
    order: "Orden",
    label: "Etiqueta",
    father: "Padre",
    parentMenu: "Menú superior",
    icon: "Ícono",
    "menus administration": "Administración de menús",
    action: "Acción",
    alias: "Alias",
    file: "Archivo",
    "file not selected": "Archivo no seleccionado",
    "choose file": "Seleccione el arhivo",
    cancel: "Cancelar",
    confirm: "Confirmar",
    "add menu": "Agregar menús",
    "edit menu": "Editar menús",
    change: "Cambiar",
    remove: "Remover",
    "this menu does not have a resource assigned. Please contact administrator for assignment":
      "Este menú no tiene ningún recurso asignado. Póngase en contacto con el administrador para la asignación",
    "new role": "Nuevo rol",
    "edit role": "Editar rol",
    "new permission": "Nuevo permiso",
    "edit permission": "Editar permiso",
    "new application": "Nuevo aplicación",
    "edit application": "Editar aplicación",
    "new user": "Nuevo usuario",
    "edit user": "Editar usuario",
    categories: "Categorias",
    products: "Productos",
    tags: "Etiquetas",
    trademark: "Marca",
    status: "Estado",
    navigate: "Navegar",
    "coming-soon": "No encontrado",
    maintenance: "Mantenimiento",
    menus: "Menús",
    "change anything related with your permission data":
      "Cambia todo lo relacionado con los datos de tus permisos",
    refresh: "Actualizar",
    "sorry, something went wrong. Try again.":
      "Perdón, algo salió mal. Intentalo otra vez.",
    "menu title": "Título del menú",
    "complete this field": "Complete este campo",
    "selected columns": "Columnas seleccionadas",
    "error loading data": "Error al cargar datos",
    "select the file to import": "Seleccione el archivo a importar",
    role: "Rol",
    "permission settings": "Configuración de permisos",
    "set the permissions you need on the role":
      "Configure los permisos que necesite en el rol",
    permissions: "Permisos",
    assigned: "Asignados",
    available: "Disponibles",
    selected: "Seleccionados",
    column: "Columna",
    "all items selected": "Todos los artículos seleccionados",
    "move selected left": "Mover seleccionado a la izquierda",
    "move selected right": "Mover seleccionado a la derecha",
    roles: "Roles",
    demomap: "DEMO Mapa",
    "new resort": "Nuevo balneario",
    "edit resort": "Editar balneario",
    "change anything related with your resort data":
      "Cambia todo lo relacionado con los datos de tu balneario",
    "enter resort name": "Ingrese el nombre del balneario",
    "enter resort email": "Ingrese el correo electrónico del balneario",
    "enter resort description": "Ingrese la descripción del balneario",
    "enter resort address": "Ingrese la dirección del balneario",
    "enter resort checkIn": "Ingrese el check in del balneario",
    "enter resort checkOut": "Ingrese el check out del balneario",
    "select city": "Seleccione la ciudad",
    "enter resort geoLocation": "Ingrese la geolocalización del balneario",
    "enter resort privateMPKey": "Ingrese la llave PRIVADA de Mercado Pago",
    "enter resort publicMPKey": "Ingrese la llave PÚBLICA de Mercado Pago",
    "geoLocation integration settings": "Configuración de geolocalización",
    "mercado pago integration settings":
      "Integración del servicio de Mercado Pago",
    "geoLocation integration settings description":
      "Ingrese los datos de configuración para la geolocalización del balneario",
    "mercado pago integration data settings description":
      "Ingrese los datos de configuración para la integración del servicio de Mercado Pago. Recuerde anotar en un lugar seguro estos datos.",
    description: "Descripción",
    address: "Dirección",
    checkIn: "Check In",
    checkOut: "Check Out",
    city: "Ciudad",
    geoLocation: "Geolocalización",
    privateMPKey: "Llave PRIVADA de Mercado Pago",
    publicMPKey: "Llave PÚBLICA de Mercado Pago",
    "new city": "Nueva ciudad",
    "edit city": "Editar ciudad",
    "change anything related with your city data":
      "Cambia todo lo relacionado con los datos de la ciudad de tu balneario",
    "enter city name": "Ingrese el nombre de la ciudad",
    "enter city description": "Ingrese una descripción de la ciudad",
    "enter city slug":
      "Modifique el slug de la ciudad. Trate de evitar símbolos raros y espacios",
    slug: "Slug",
    cities: "Ciudades",
    "enter resort slug":
      "Modifique el slug del balneario. Trate de evitar símbolos raros y espacios",
    resorts: "Balnearios",
    "change anything related with your amenity data":
      "Cambia todo lo relacionado con los datos de la comodidad",
    "enter amenity name": "Ingrese el nombre de la comodidad",
    "new amenity": "Nueva comodidad",
    "edit amenity": "Editar comodidad",
    amenities: "Comodidades",
    "enter amenity icon":
      "Ingrese el ícono que va a representar esta comodidad. Por favor, recuerde elegir una imagen del tamaño de un icono aproximado a 25x25 px",
    validity: "Vigencia",
    enabled: "Habilitada",
    promotions: "Promociones",
    url: "URL",
    "new brand": "Nueva marca",
    "edit brand": "Editar marca",
    "brand image": "Logo de la marca",
    "change anything related with the brand data":
      "Cambia todo lo relacionado con los datos de la marca",
    "enter the image that represents the brand":
      "Ingrese el logo que representa la marca",
    "enter brand name": "Ingrese el nombre de la marca",
    "enter brand description": "Ingrese la descriptión de la marca",
    "enter brand order":
      "Ingrese el nro de orden en el que se mostrará la marca",
    brands: "Brands",
    logo: "Logo",
    "change anything related with the category data":
      "Cambia todo lo relacionado con los datos de la categoría",
    "enter the image that represents the category":
      "Ingrese la imagen que representa la categoría",
    "enter category name": "Ingrese el nombre de la categoría",
    "enter category slug": "Ingrese el slug de la categoría",
    "enter category description": "Ingrese la descripción de la categoría",
    "select parent category":
      "Seleccione la categoría superior si corresponde.",
    "category image": "Imagen de la categoría",
    parentCategory: "Categoría superior",
    image: "Imagen",
    "new characteristic": "Nueva característica",
    "edit characteristic": "Editar característica",
    "change anything related with the characteristic data":
      "Cambia todo lo relacionado con los datos de la característica",
    "enter the icon that represents the characteristic":
      "Ingrese el ícono que represente a la característica",
    "characteristic icon": "Ícono de la característica",
    "enter characteristic name": "Ingrese el nombre de la característica",
    characteristics: "Caraterísticas",
    sku: "SKU",
    category: "Categoría",
    brand: "Marca",
    price: "Precio",
    "change anything related with the product data":
      "Cambia todo lo relacionado con los datos del producto",
    "enter product sku": "Ingrese el SKU del producto",
    "enter product name": "Ingrese el nombre del producto",
    "enter product description": "Ingrese las descripción del producto",
    "enter product price": "Ingrese el precio base del producto",
    "select product category":
      "Seleccione la categoría a la que pertenece el producto",
    "select product brand": "Ingrese la marca del producto",
    "enter the images of the product. Please ensure that images do not exceed a resolution of 1920x1280":
      "Ingrese las diferentes imágenes del producto. Procure que las imágenes no superen una resolución de 1920x1280",
    "product images": "Imagenes del producto",
    gallery: "Galería",
    "error uploading media": "Hubo un error al subir la imagen/video",
    "parent category": "Categoría superior",
    "enter category order": "Ingrese el orden de la categoría",
    "new inventory": "Nuevo zona",
    "edit inventory": "Editar zona",
    "change anything related with your inventory data":
      "Cambia todo lo relacionado con los datos de la zona",
    "enter inventory name": "Ingrese el nombre del zona",
    "enter inventory description": "Ingrese la descripción del zona",
    inventory: "Zona",
    inventories: "Zonas",
    "confirm delete": "Confirma eliminar",
    "resort images": "Imágenes del balneario",
    "enter the images of the resort. Please ensure that images do not exceed a resolution of 1920x1280":
      "Ingrese las diferentes imágenes del balneario. Procure que las imágenes no superen una resolución de 1920x1280",
    "select the resort the user belongs to if applicable":
      "Seleccione el balneario al que pertenece el usuario si corresponde",
    resort: "Balneario",
    repassword: "Repetir contraseña",
    "enter the password again": "Ingrese la contraseña nuevamente",
    "select the user role": "Seleccione el rol del usuario",
    "amenities settings": "Configuración de comodidades",
    "select the amenities your resort has":
      "Seleccione las comodidades que tiene su balneario",
    "assigned amenities": "Comodidades asignadas",
    "assign amenities from the list of available ones":
      "Asigne las comodidades que desee del listado de disponibles",
    "if any comfort requires any extra information, it can be added to the following list":
      "Si alguna comodidad necesita algún dato extra se puede agregar en el siguiente listado",
    "extra data": "Datos extra",
    "you need to assign a convenience to add its extra value":
      "Necesita asignar una comodidad para agregar su valor extra",
    "you do not have permissions to enter this menu":
      "No tiene permisos para ingresar a este menú",
    "username input description":
      "Ingresa tu nombre de usuario. Puede ser cualquier combinación alfanumética, tanto con _ como con -, tener al menos 4 caracteres y no superar los 25 caracteres",
    "invalid username": "Nombre de usuario inválido",
    "passwords differ": "Las contraseñas difieren",
    "password input description":
      "Esta es tu contraseña principal. Puede ser cualquier combinación alfanumética, tanto con _ como con -, tener al menos 8 caracteres y no superar los 25 caracteres",
    "invalid password": "Contraseña inválida",
    product: "Producto",
    start: "Comienza",
    end: "Finaliza",
    priceschedules: "Agenda de precios",
    "enter priceSchedule price":
      "Ingrese el precio del producto a tener en cuenta para el rango de fechas especificado anteriormente",
    "enter priceSchedule end":
      "Ingrese la fecha hasta donde se tiene en cuenta el precio a ingresar del producto",
    "enter priceSchedule start":
      "Ingrese la fecha desde donde se tiene en cuenta el precio a ingresar del producto",
    "select priceSchedule product":
      "Seleccione el producto al cual va a agendar el precio en un rango de fechas",
    "change anything related with your priceSchedule data":
      "Cambia todo lo relacionado con la agenda de precios de un producto entre dos fechas",
    "new priceSchedule": "Nueva agenda de precios",
    "edit priceSchedule": "Editar agenda de precios",
    "Do you want to delete?": "¿Desea eliminar?",
    zone: "Zona",
    zones: "Zonas",
    "change anything related with your password":
      "Cambia todo lo relacionado con tu contraseña",
    "manage password": "Configuracion de contraseña",
    "enter your new password": "Ingresa tu nueva contraseña",
    "enter your old password": "Ingresa tu vieja contraseña",
    "re-enter your new password": "Vuelve a ingresar tu nueva contraseña",
    "old password": "Vieja Contraseña",
    "inconsistent passwords": "Ingresaste dos contraseñas diferentes",
    "Passwords do not match": "La vieja contraseña no coincide",
    "successful password update": "Actualización de contraseña exitosa",
    services: "Servicios",
    "select the services that the resort has":
      "Seleccione los servicios que tiene el balneario",
    "online service": "Servicio en línea",
    "this service enables the resort to appear on the Alamar page":
      "Este servicio habilita al balneario a aparecer en la página de Alamar",
    "enable onlineService": "Habilitar servicio en línea",
    "booking service": "Servicio de reserva",
    "this service enables the booking system":
      "Este servicio habilita el sistema de reservas",
    "enable bookingSystem": "Habilitar sistema de reservas",
    online: "En línea",
    identifier: "Identificador",
    productszone: "Productos por zona",
    instanceszone: "Instancias por zona",
    "new instance": "Nueva instancia",
    "edit instance": "Editar instancia",
    "change anything related with the product instance data":
      "Cambia todo lo relacionado con los datos de la instancia de producto",
    "select product type of the instance":
      "Seleccione el tipo de producto de la instancia",
    "select the zone to which the instance belongs":
      "Seleccione la zona a la que pertenece la instancia",
    "enter the instance identifier": "Ingrese el identificador de la instancia",
    "instance availability": "Disponibilidad de la instancia",
    "changes instance availability in different contexts":
      "Cambia la disponibilidad de la instancia en diferentes contextos",
    "this enables the instance on the Alamar page":
      "Esto habilita la instancia en la página de Alamar",
    "this enable/disabled the instance":
      "Esto habilita/desabilita totalmente la instancia",
    "enable online instance": "Habilitar instancia en línea",
    "enable instance": "Habilitar instancia",
    "you must belong to a spa to add an instance":
      "Debe pertenecer a un balneario para agregar una instancia",
    "new booking": "Nueva reserva",
    "edit booking": "Editar reserva",
    "reference data": "Datos de referencia",
    "reference data of the person making the booking":
      "Datos de referencia de la persona que está hace la reserva",
    "at least one of these fields must be completed":
      "Al menos uno de estos campos debe ser completado",
    "booking dates": "Fechas de la reserva",
    "selection of the start and end date of the booking":
      "Selección de la fecha de inicio y finalización de la reserva",
    "details of the booking": "Detalles de la reserva",
    "details of items being reserved":
      "Detalle de los elementos que están siendo reservados",
    "payment record": "Registro de pagos",
    "record of payments made": "Registro de los pagos que se han hecho",
    "enter the name of the person making the booking":
      "Ingrese el nombre de la persona que está haciendo la reserva",
    "enter the document of the person making the booking":
      "Ingrese el documento de la persona que está haciendo la reserva",
    "enter the phone of the person making the booking":
      "Ingrese el teléfono de la persona que está haciendo la reserva",
    "enter the email of the person making the booking":
      "Ingrese el correo electrónico de la persona que está haciendo la reserva",
    "select the user making the booking if applicable":
      "Seleccione el usuario que está realizando la reserva, si corresponde",
    user: "Usuario",
    "start date": "Fecha de inicio",
    "enter the start date of the booking":
      "Ingrese la fecha de inicio de la reserva",
    "end date": "Fecha de finalización",
    "enter the end date of the booking":
      "Ingrese la fecha de finalización de la reserva",
    paid: "Monto pagado",
    "enter the amount that has been paid":
      "Ingrese el monto que ha sido pagado",
    discount: "Descuento",
    from: "Desde",
    to: "Hasta",
    document: "Documento",
    token: "Token",
    bookings: "Reservas",
    maps: "Mapas",
    "available instances": "Instancias disponibles",
    "sidebar map instances description":
      "Arrastre y ubique en el mapa las instancias. Haciendo click en la instancia en el mapa puede cambiar la orientación de la misma",
    "map width": "Ancho del mapa",
    "map height": "Alto del mapa",
    "save map": "Guardar mapa",
    "decoration elements": "Elementos decorativos",
    "decoration elements description":
      "Arrastre y ubique en el mapa los elementos decorativos que desee. Haciendo click en el elemento en el mapa puede cambiar la orientación del mismo",
    "there are no items to locate": "No hay elementos para ubicar",
    "map updated": "Mapa actualizado",
    editor: "Editor",
    source: "Origen",
    "you can assign by selecting the zone and then entering the ID of the instance in that zone or transferring from the list below":
      "Puede asignar seleccionando la zona y luego ingresando el identificador de la instancia en esa zona y presionando Enter, o puede asignar transfiriendo de la lista de abajo",
    "assigned instances": "Instancias asignadas",
    "the instance is already assigned or is not available":
      "La instancia ya esta asignada o no está disponible",
    "instance not exist": "La instancia no existe",
    totals: "Totales",
    "information on the total cost of the booking":
      "Información del costo total de la reserva",
    "discount calculated from the reservation":
        "Descuento calculado de la reserva",
    "manual discount calculated from the reservation":
      "Descuento calculado de la reserva (puede ser modificado)",
    "net total": "Total neto",
    "total calculated from the reservation":
      "Total neto calculado de la reserva",
    "enter dates": "Ingrese las fechas",
    "select the reservation dates to be able to select the instances to reserve":
      "Seleccione las fechas de reserva para poder seleccionar las instancias a reservar",
    "There is an price schedule overlap":
      "Hay una superposición de agenda de precios",
    "agenda created successfully": "Agenda creada satisfactoriamente",
    "enable manual subtotal": "Habilitar subtotal manual",
    "enable manual discount": "Habilitar descuento manual",
    "booking created successfully": "Reserva creada satisfactoriamente",
    "remains to pay": "Resta pagar",
    view: "Ver",
    "personal data": "Datos personales",
    booking: "Reserva",
    reserved: "Reservado",
    "not available": "No disponible",
    book: "Reservar",
    "search booking": "Buscar reserva",
    "booking not found": "No se encontró la reserva",
    "agenda updated successfully": "Agenda actualizada satisfactoriamente",
    "could not get weather": "No se pudo obtener el clima",
    "new password": "Nueva contraseña",
    "invalid email": "Correo electrónico inválido",
    "password changed successfully": "Contraseña cambiada exitosamente",
    send: "Enviar",
    "you must belong to a resort to see the map":
      "Debe pertenecer a un balneario para poder ver el mapa",
    "count days": "Cant. días",
    "please enter a valid date range": "Ingrese un rango de fecha válido",
    "enter reservation token, name, document or email":
      "Ingrese token, nombre, documento o correo electrónico de la reserva",
    "new promotion": "Nueva promoción",
    "edit promotion": "Editar promoción",
    "change anything related with your promotion data":
      "Cambia todo lo relacionado con los datos de la promoción",
    "enter promotion name": "Ingrese el nombre de la promoción",
    "enter promotion description": "Ingrese la descripción de la promoción",
    "enter promotion validityFrom":
      "Ingrese el inicio de la validez de la promoción",
    "enter promotion validityTo":
      "Ingrese el fin de la validez de la promoción",
    "enter promotion url": "Ingrese la URL de la promoción, si corresponde",
    "this enable/disabled the promotion":
      "Esto habilita/deshabilita la promoción",
    "enable promotion": "Habilitar promoción",
    validityFrom: "Inicio de validez",
    validityTo: "Fin de validez",
    "validity settings": "Configuración de la validez",
    "change everything related to the validity of the promotion":
      "Cambia todo lo relacionado a la validez de la promoción",
    "parameters settings": "Configuración de parámetros",
    "configure promotion parameters":
      "Configure los parámetros que van a activar la promoción",
    "promotions parameters": "Parámetros de la promoción",
    "add promotions parameters": "Agrega parámetros a la promoción",
    productsQuantity: "Cantidad de productos",
    daysQuantity: "Cantidad de días",
    greaterDaysQuantity: "Mayor a cantidad de días",
    dateRanges: "Rango de fechas",
    coupon: "Cupón",
    "add parameter": "Agregar parámetro",
    "selected products": "Productos seleccionados",
    "quantity products": "Cantidad de productos",
    "quantity days": "Cantidad de días",
    "greater quantity days": "Cantidad de días mayor a...",
    "price modifier settings": "Configuración de modificación del precio",
    "configure price modifier":
      "Configure de qué forma se va a modificar el precio según la promoción",
    "promotions price modifier": "Modificador del precio de la promoción",
    "select price modifier": "Seleccione el modificador del precio",
    DirectDiscountInMoney: "Descuento directo en la moneda",
    DirectDiscountInPercentage: "Descuento directo en porcentaje",
    TotalDirectValue: "Valor total directo",
    DirectValuePerDay: "Valor directo por día",
    amount: "Monto",
    percentage: "Procentaje",
    "the parameters and the price modifier must be configured":
      "Se debe configurar los parametros y el modificador del precio",
    "the date range must be selected": "Se debe seleccionar el rango de fechas",
    "promotion updated successfully":
      "Promoción actualizada satisfactoriamente",
    "promotion created successfully": "Promoción creada satisfactoriamente",
    resume: "Resumen",
    loading: "Cargando",
    "a zone must be selected": "Se debe seleccionar una zona",
    "matching promotions per instance":
      "Promociones coincidentes por instancia",
    "charge payment": "Cargar pago",
    "new payment": "Nuevo pago",
    "payment changed successfully": "Pago cambiado satisfactoriamente",
    "new amount paid": "Nuevo monto pagado",
    "enter valid amount": "Ingrese un monto válido",
    "associated categories": "Categorías asociadas",
    "amenity.config.categories.description":
      "Determine con qué categorías está asociada la comodidad",
    "hold down the Ctrl button to select multiple options":
      "Mantenga presionado el botón Ctrl para seleccionar múltiples opciones",
    "searcheable columns": "Columnas buscables:",
    "enter the city of origin of the person making the booking": "Ingrese la ciudad de origen de la persona que está haciendo la reserva",
    "active": "Activa",
    "change anything related with your season data": "Cambia todo lo relacionado con los datos de la temporada",
    "new season": "Nueva temporada",
    "enter season name": "Ingrese el nombre de la temporada",
    "enter season description": "Ingrese una descripción de la temporada",
    "enter season start": "Ingrese cuándo comienza la temporada",
    "enter season end": "Ingrese cuándo termina la temporada",
    "this enables the season (only one season can be enabled)": "Esto habilita temporada (solo una temporada puede estar habilitada a la vez)",
    "enable season": "Habilitar temporada",
    "There is already an active season": "Ya existe una temporada activa",
    "There is overlapping of season": "Existe superposición de temporadas",
    "season created successfully": "Temporada creada satisfactoriamente",
    "initial identifier": "Identificador inicial",
    "enter the initial instance identifier": "Ingrese el identificador inicial",
    "quantity identifiers": "Cantidad de identificadores",
    "enter quantity identifiers": "Ingrese la cantidad de identificadores",
    "create massive instances": "Crear instancias masivamente",
    "Error creating massive instances": "Hubo un error al crear las instancias",
    "instances created": "Instancias creadas",
    "instances omitted": "Instancias omitidas",
    "add massive": "Agregar masivamente",
    "user settings": "Configuración de perfil de usuario",
    "change anything related with your user profile data": "Cambia todo lo relacionado con los datos de tu perfil de usuario",
    "user profile": "Perfil de usuario",
    "resort profile": "Perfil del balneario",
    "website": "Alamar",
    "api": "API",
    "enter resort phone": "Ingrese el teléfono del balneario",
    "income summary": "Resumen de ingresos",
    "resort mode": "Modo del balneario",
    "select resort mode": "Seleccione el modo del balneario",
    "local resort mode description": "SOLO LOCAL: Solo servicio de office",
    "intermediate resort mode description": "INTERMEDIO: Servicio office más web sin reserva",
    "full resort mode description": "FULL: Servicio office más reserva web",
    "local": "SOLO LOCAL",
    "intermediate": "INTERMEDIO",
    "full": "FULL",
    "draft": "Borrador",
    "LIKE": 'Similar a',
    "STARTS_WITH": 'Comienza con',
    "ENDS_WITH": 'Termina con',
    "EQUAL": 'Igual a',
    "ONLY": 'Solo',
    "SOME": 'Alguno',
    "GREATER_THAN": 'Mayor que',
    "GREATER_OR_EQUAL_THAN": 'Mayor igual que',
    "LESS_THAN": 'Menor que',
    "LESS_OR_EQUAL_THAN": 'Menor igual que',
    "BETWEEN": 'Entre',
    "DATE_RANGE": 'Entre fechas',
    "office": "Balneario",
    "The booking has no details": "La reserva no tiene detalles de los items",
    "Bookings must be made at a defined resort": "Las reservas deben indicar balneario correspondiente",
    "There is an overlap of bookings": "Existe una superposición de reservas",
    "code": "Código",
    "subtotal": "Subtotal",
    "subtotal from the reservation": "Subtotal de la reserva",
    "manual total from the reservation": "Total de la reserva (puede ser modificado)",
    "guided": "guiada",
    "total from the reservation": "Total de la reserva",
  },
};

export default spanishLocale;
