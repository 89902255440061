import TableHead from "./components/TableHead";
import { SortColumnProps, TableProps } from "./domain/table.model";
import { StyledTable } from "./styled-components/StyledTable";
import TableBody from "./components/TableBody";
import {
  StyledActionsSection,
  StyledActionsZone,
  StyledTableZone,
  StyledActionsSectionRight,
  TableContainer,
  StyledActionsSectionCenter,
} from "./styled-components/TableContainer";
import TablePagination from "./components/TablePagination";
import TableFilter from "./components/TableFilters/TableFilter";
import TableSearch from "./components/TableSearch/TableSearch";
import {
  StyledTableAddButtonName,
  StyledTableFreeAction,
  StyledTableFreeActions,
} from "./styled-components/StyledTableAdd";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { ColumnFilter, TableColumn, ValueType } from "./domain/columns.model";
import { FilterTag } from "./components/TableFilters/FilterTag";
import useTableColumnSelector, {
  ColumnSelectable,
} from "./tableUtils/columnSelector.hook";
import { useColumnExchange } from "./tableUtils/columnExchange.hook";
import {StyledMassiveActions} from "./styled-components/StyledTableActions";

function Table<T>({
  tableName = "mytable",
  columns,
  data,
  parameters,
  onChangeParameters,
  pageSizeOptions = [20, 50, 100],
  actions = [],
  specialAction,
    massiveActions,
}: TableProps<T>) {
  const { data: rows, total } = data ?? { data: [], total: 0 };
  const freeActions = actions?.filter((act) => act.freeAction);
  const initialColumns: ColumnSelectable[] = columns.map((col) => ({
    field: col.field,
    label: col.label,
  }));
  const { selectedColumns, addColumn, removeColumn } = useTableColumnSelector(
    tableName,
    initialColumns
  );
  const { columnsOrder, exchangeColumns } = useColumnExchange(
    tableName,
    columns.map((col) => col.field)
  );

  const haveFilters =
    columns.filter((col) => col.filterOperators !== undefined).length > 0;

  const handlePageChange = (page: number) => {
    onChangeParameters({
      ...parameters,
      page,
    });
  };

  const handlePageSizeChange = (pageSize: number) => {
    onChangeParameters({
      ...parameters,
      page: 1,
      pageSize,
    });
  };

  const handleSortColumnChange = (newSort: SortColumnProps) => {
    onChangeParameters({
      ...parameters,
      order: newSort,
    });
  };

  const handleAddFilter = (filter: ColumnFilter<ValueType>) => {
    onChangeParameters({
      ...parameters,
      page: 1,
      filters: parameters?.filters
        ? [...parameters?.filters, filter]
        : [filter],
    });
  };

  const handleRemoveFilter = (filterToRemove: ColumnFilter<ValueType>) => {
    const newFilters = parameters?.filters?.filter(
      (filter) => filter !== filterToRemove
    );
    onChangeParameters({
      ...parameters,
      page: 1,
      filters: newFilters ? [...newFilters] : [],
    });
  };

  const handleChangeSearch = (newSearch: string) => {
    onChangeParameters({
      ...parameters,
      search: newSearch,
    });
  };

  return (
    <TableContainer>
      {parameters?.search || freeActions?.length > 0 || haveFilters ? (
        <StyledActionsZone>
          <StyledActionsSection>
            {freeActions?.length > 0 ? (
              <StyledTableFreeActions>
                {freeActions?.map((action, index) => {
                  return (
                    <StyledTableFreeAction
                      onClick={() => action.toExecute()}
                      key={`table_free_action_${index}`}
                    >
                      {action.icon ? action.icon : <AddIcon />}
                      <StyledTableAddButtonName>
                        {action.name}
                      </StyledTableAddButtonName>
                    </StyledTableFreeAction>
                  );
                })}
              </StyledTableFreeActions>
            ) : null}
          </StyledActionsSection>
          <StyledActionsSectionCenter>
            {parameters?.filters?.map((filter, index) => {
              return (
                <FilterTag
                  key={`filtertag_${index}`}
                  filter={filter}
                  onRemove={handleRemoveFilter}
                />
              );
            })}
          </StyledActionsSectionCenter>
          <StyledActionsSectionRight>
            {
              specialAction ?
                  specialAction
                  : null
            }
            {parameters?.searchableColumns &&
            parameters?.searchableColumns?.length > 0 ? (
              <TableSearch<T>
                search={parameters.search}
                onChangeSearch={handleChangeSearch}
                haveFilters={haveFilters}
                columns={parameters.searchableColumns}
                tooltipLabels={parameters.searchableTooltipLabels}
              />
            ) : null}
            {haveFilters ? (
              <TableFilter
                columns={columns.filter(
                  (column: TableColumn<T>) => !!column.filterOperators
                )}
                filters={parameters.filters}
                addFilter={handleAddFilter}
              />
            ) : null}

              {
                massiveActions && massiveActions.length > 0 ?
                    <StyledMassiveActions>
                      {
                        massiveActions.map((massiveAction, index: number) => (
                            <React.Fragment key={`massiveAction_${index}`}>
                              {massiveAction(rows)}
                            </React.Fragment>
                        ))
                      }
                    </StyledMassiveActions>
                    : null
              }
          </StyledActionsSectionRight>
        </StyledActionsZone>
      ) : null}

      <StyledTableZone>
        <StyledTable>
          <TableHead
            tableName={tableName}
            columns={columns}
            sortColumn={parameters.order}
            onShortColumnChange={handleSortColumnChange}
            initialColumns={initialColumns}
            selectedColumns={selectedColumns}
            addColumn={addColumn}
            removeColumn={removeColumn}
            columnsOrder={columnsOrder}
            onExchangeColumns={exchangeColumns}
          />
          <TableBody<T>
            columns={columns}
            rows={rows}
            actions={actions}
            selectedColumns={selectedColumns}
            columnsOrder={columnsOrder}
          />
        </StyledTable>
      </StyledTableZone>
      <TablePagination
        show={total !== undefined && total > 0}
        currentPage={parameters.page}
        pageSize={parameters.pageSize}
        total={total}
        pageOptions={pageSizeOptions}
        onPerPageChange={handlePageSizeChange}
        onPageChange={handlePageChange}
      />
    </TableContainer>
  );
}

export default Table;
