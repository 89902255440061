import { StyledTableActions } from "../styled-components/StyledTableActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledButtonActions } from "../styled-components/StyledButtonActions";
import { TableActionsProps } from "../domain/table.model";
import TableAction from "./TableAction";
import { useEffect, useRef, useState } from "react";

interface TableActionsContainerProps<T> {
  data: T;
  actions: TableActionsProps<T>[];
}
function TableActionsContainer<T>({
  data,
  actions,
}: TableActionsContainerProps<T>) {
  const [show, setShow] = useState<boolean>(false);

  const showActions = () => {
    setShow(!show);
  };

  const closeActions = () => {
    setShow(false);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const restrictAction = (action: TableActionsProps<T>) => {
    return action.restrictShow && action.restrictShow(data);
  }

  return (
    <div ref={ref}>
      {
        actions.filter(action => !action.freeAction).length === 1 ?
            <TableAction
                data={data}
                action={actions.filter(action => !action.freeAction)[0]}
                onClose={closeActions}
                onlyIcon={true}
            />
            :
            <>
              <StyledButtonActions onClick={showActions}>
                <MoreVertIcon />
              </StyledButtonActions>
              <StyledTableActions show={show}>
                {actions.map((action, index) =>
                    !action.freeAction && !restrictAction(action) ? (
                        <TableAction
                            key={`action_table_${index}`}
                            data={data}
                            action={action}
                            onClose={closeActions}
                        />
                    ) : null
                )}
              </StyledTableActions>
            </>
      }

    </div>
  );
}

export default TableActionsContainer;
