import styled from "styled-components";

interface StyledTableActionsProps {
    show: boolean;
}

interface StyledTableActionProps {
    color?: string;
}

export const StyledTableActions = styled.div<StyledTableActionsProps>`
  position: absolute;
  top: 90%;
  right: 0;
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
  min-width: 150px;
  background: ${props => props.theme.table.tableActionsBackground};
  z-index: 9;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
  border-radius: 8px;
`

export const StyledAction = styled.div<StyledTableActionProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  color: ${props => props.color ? props.color : props.theme.table.tableActionsTextColor};
  &:hover {
    background: rgba(0,0,0,0.1);
  }
`

export const StyledActionName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`

export const StyledMassiveActions = styled.div`
    
`