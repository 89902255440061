import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {TableOptions} from "../../shared-kernel/components/Table";
import {appConfig} from "../../config";
import {EndpointSeason, Season, SeasonDataProps} from "../domain/Season.model";
import {createAdapterSeason} from "../adapters/season.adapter";

const API_URL = appConfig.API_URL;

export const seasonApi = createApi({
    reducerPath: "SeasonApi",
    baseQuery: fetchBaseQuery({
      baseUrl: API_URL,
      prepareHeaders: (headers: Headers) => {
        const APP_TOKEN = "app_token";
        const token = localStorage.getItem(APP_TOKEN);

        if (token) {
          headers.set("authorization", `Bearer ${token}`);
          headers.set("Content-Type", "application/json");
        }

        return headers;
      },
      credentials: "include",
    }),
    tagTypes: ["Seasons"],
    endpoints: (builder) => ({
      getSeasons: builder.query<SeasonDataProps, {resortId: string; tableOptions: TableOptions<Season> | undefined}>({
        query: ({resortId, tableOptions}) =>{
          let queryParams: string = `resortId=${resortId}&`;
          if(tableOptions !== undefined && tableOptions.page){
            const page = tableOptions.page - 1;
            queryParams += `page=${page}`;
          }
          if(tableOptions !== undefined && tableOptions.pageSize){
            queryParams += `&pageSize=${tableOptions.pageSize}`;
          }
          if(tableOptions !== undefined && tableOptions.order){
            const parsedOrder = JSON.stringify({
              field: tableOptions.order.field,
              order: tableOptions.order.order
            })
            queryParams += `&order=${parsedOrder}`;
          }
          if(tableOptions !== undefined && tableOptions.search){
            queryParams += `&search=${tableOptions.search}`;
          }
          return `seasons${queryParams !== '' ? `?${queryParams}` : ''}`
        }
          ,
        providesTags: ["Seasons"],
        transformResponse: (response: any) => {
          return {
            data: response.map((s: EndpointSeason) => createAdapterSeason(s)),
            page: 1,
            total: response.length,
          };
        }
      }),
      updateSeason: builder.mutation<any, EndpointSeason>({
        query: (data: EndpointSeason) => ({
          url: `seasons/${data.id}`,
          method: "PUT",
          body: createAdapterSeason(data),
        }),
        invalidatesTags: ["Seasons"],
      }),
      getSeasonById: builder.query<EndpointSeason, string | undefined>({
        query: (seasonId) => `seasons/${seasonId}`,
        providesTags: (result, error, seasonId) => [{ type: 'Seasons', id: seasonId }],
        transformResponse: (response: EndpointSeason) => createAdapterSeason(response),
      }),
      createNewSeason: builder.mutation<any, any>({
        query: (data) => ({
          url: 'seasons/',
          method: "POST",
          body: data,
        }),
        invalidatesTags: ["Seasons"],
      }),
      deleteSeason: builder.mutation<any, any>({
        query: (id) => ({
          url: `seasons/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ['Seasons']
      }),
    }),
  });

export const { useCreateNewSeasonMutation, useDeleteSeasonMutation, useGetSeasonByIdQuery, useGetSeasonsQuery, useUpdateSeasonMutation } = seasonApi;
