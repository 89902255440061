import {
    ColumnFilter,
    ColumnType,
    DateFilterOperator,
    IdentifierFilterOperator,
    NumberFilterOperator,
    TextFilterOperator,
    ValueType
} from "../domain/columns.model";
import {InputType} from "../../Inputs/Input";
import {TableOptions} from "../domain/table.model";

enum CompareResults {
    GREATER= 1,
    LESS = -1,
    EQUAL = 0
}

export function getAlignByColumnType(columnType: ColumnType): string{
    switch (columnType) {
        case ColumnType.DATE:
            return 'center';
        case ColumnType.NUMBER:
            return 'right';
        case ColumnType.TEXT:
            return 'left';
        default:
            return 'left';
    }
}

function searchFilter<T>(search: string, data: T[], columns: (keyof T)[]){
    return data.filter((item:T) => matchData(search, item, columns));
}

/*function valuateOperator(a: any, b: any, operator: string): boolean {

    switch (operator) {
        case TextFilterOperator.CONTAINS:
            return b.toLowerCase().includes(a.toLowerCase());
        case TextFilterOperator.DOES_NOT_CONTAIN:
            return !(b.toLowerCase().includes(a.toLowerCase()));
        case TextFilterOperator.STARTS_WITH:
            return b.toLowerCase().startsWith(a.toLowerCase());
        case TextFilterOperator.ENDS_WITH:
            return b.toLowerCase().endsWith(a.toLowerCase());
        case TextFilterOperator.EQUALS:
            return b.trim() === a.trim();
        case TextFilterOperator.NOT_EQUALS:
            return b !== a;
        case NumberFilterOperator.EQUALS:
            // eslint-disable-next-line eqeqeq
            return a == b;
        case NumberFilterOperator.NOT_EQUALS:
            // eslint-disable-next-line eqeqeq
            return a != b;
        case NumberFilterOperator.GREATER_THAN:
            return a < b;
        case NumberFilterOperator.GREATER_THAN_OR_EQUAL:
            // eslint-disable-next-line eqeqeq
            return a < b || a == b;
        case NumberFilterOperator.LESS_THAN:
            return a > b;
        case NumberFilterOperator.LESS_THAN_OR_EQUAL:
            // eslint-disable-next-line eqeqeq
            return a > b || a == b;
        case IdentifierFilterOperator.SOME:
            // eslint-disable-next-line eqeqeq
            return a.id == b;
        case IdentifierFilterOperator.ONLY:
            // eslint-disable-next-line eqeqeq
            return a.id == b;
        case DateFilterOperator.EQUALS:
            // eslint-disable-next-line eqeqeq
            return a == b;
        case DateFilterOperator.NOT_EQUALS:
            // eslint-disable-next-line eqeqeq
            return a != b;
        case DateFilterOperator.AFTER:
            return compareStringDate(a,b) === CompareResults.LESS;
        case DateFilterOperator.BEFORE:
            return compareStringDate(a,b) === CompareResults.GREATER;
        default:
            return false;
    }
}*/

function compareStringDate(inputDate: string, toCompareDate: string): number { // 1 greater, 0 equal, -1 less
    const inputDateParts = inputDate.split('-'); // inputDate is a string of type 'yyyy-mm-dd'
    const toCompareDateParts = toCompareDate.split('-'); // toCompareDate is a string of type 'yyyy-mm-dd'
    const yearInputDate = inputDateParts[0];
    const yearToCompareDate = toCompareDateParts[0];
    const isInputYearGreater = parseInt(yearInputDate) > parseInt(yearToCompareDate);
    const isInputYearEqual = parseInt(yearInputDate) === parseInt(yearToCompareDate);
    if(isInputYearGreater){
        return CompareResults.GREATER;
    }
    if(isInputYearEqual) {
        const monthInputDate = inputDateParts[1];
        const monthToCompareDate = toCompareDateParts[1];
        const isInputMonthGreater = parseInt(monthInputDate) > parseInt(monthToCompareDate);
        const isInputMonthEqual = parseInt(monthInputDate) === parseInt(monthToCompareDate);
        if(isInputMonthGreater) {
            return CompareResults.GREATER;
        }
        if(isInputMonthEqual) {
            const dayInputDate = inputDateParts[2];
            const dayToCompareDate = toCompareDateParts[2];
            const isInputDayGreater = parseInt(dayInputDate) > parseInt(dayToCompareDate);
            const isInputDayEqual = parseInt(dayInputDate) === parseInt(dayToCompareDate);
            if(isInputDayGreater) {
                return CompareResults.GREATER;
            }
            if(isInputDayEqual) {
                return CompareResults.EQUAL;
            }
        }
    }
    return CompareResults.LESS;
}

/*function valuateFilter(filter: ColumnFilter<ValueType>, data: any): boolean {
    let flag = false;
    if(filter.operator === NumberFilterOperator.BETWEEN){
        if(filter.column && filter.values){
            return valuateOperator(filter.values[0].value, data[filter.column], NumberFilterOperator.GREATER_THAN) && valuateOperator(filter.values[1].value, data[filter.column], NumberFilterOperator.LESS_THAN);
        }
        return false;
    }
    if(filter.operator === DateFilterOperator.DATE_RANGE){
        if(filter.column && filter.values){
            return valuateOperator(filter.values[0].value, data[filter.column], DateFilterOperator.AFTER) && valuateOperator(filter.values[1].value, data[filter.column], DateFilterOperator.BEFORE);
        }
        return false;
    }
    if(filter.operator === TextFilterOperator.DOES_NOT_CONTAIN){
        flag = true;
        filter.values?.forEach(val => {
            flag = flag && !!filter.column && !!filter.operator && valuateOperator(val.value, data[filter.column], filter.operator);
        })
        return flag;
    }
    filter.values?.forEach(val => {
        if(filter.column && filter.operator && valuateOperator(val.value, data[filter.column], filter.operator)){
            flag = true;
        }
    })
    return flag;
}*/

/*function filterTable(filters: ColumnFilter<ValueType>[], data: any[]): any[]{
    return data.filter(item => {
        let flag = true;
        filters.forEach(filter => {
            flag = flag && valuateFilter(filter, item);
        })
        return flag;
    });
}*/


function matchData<T>(search: any, data: T, columns: (keyof T)[]): boolean {
    return columns.some((column) => {
        const value = data[column];
        if (typeof value === 'string') {
            return value.toLowerCase().includes(search.toLowerCase());
        }
        if (typeof value === 'number') {
            // eslint-disable-next-line eqeqeq
            return value == search;
        }
        // eslint-disable-next-line eqeqeq
        return value == search;
    });
}

export function getInputTypeFromColumnType(columnType?: ColumnType): InputType {
    if(!columnType){
        return InputType.TEXT;
    }
    switch (columnType) {
        case ColumnType.TEXT:
            return InputType.TEXT;
        case ColumnType.NUMBER:
            return InputType.NUMBER;
        case ColumnType.DATE:
            return InputType.DATE;
        default:
            return InputType.TEXT;
    }
}


/*export function manageLocalFilters<T>(itemsList: T[], params: TableOptions<T> | undefined){
    const search = params?.search;
    const searchableColumns = params?.searchableColumns ?? [];
    const filters = params?.filters ?? [];
    let endpointsItems = itemsList;
    if(search && search !== '' && searchableColumns.length > 0){
        endpointsItems = searchFilter(search, endpointsItems, searchableColumns);
    }
    if(filters.length > 0){
        endpointsItems = filterTable(filters, endpointsItems);
    }
    return endpointsItems;
}*/

export function withTableFilters<T>(url:string, params: TableOptions<T> | undefined){
    // TODO: Continue filters...
    /*let queryParams: string = '';
    const page = params?.page ?? 1;
    const pageSize = params?.pageSize ?? 20;
    const order = params?.order;
    const search = params?.search;

    /!*const searchableColumns = params?.searchableColumns ?? [];
    const filters = params?.filters ?? [];*!/
    queryParams += `page=${page}&pageSize=${pageSize}`;
    if(order){
        const parsedOrder = JSON.stringify({
            field: order.field,
            order: order.order
        })
        queryParams += `&order=${parsedOrder}`;
    }
    if(search){
        queryParams += `&search=${search}`;
    }*/
}