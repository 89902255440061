import {ReactNode} from "react";

export type TableColumn<T> = {
  field: string;
  label: string;
  type: ColumnType;
  filterOperators?: TextFilterOperator[] | NumberFilterOperator[] | DateFilterOperator[] | IdentifierFilterOperator[];
  filterOptions?: FilterOption[];
  textAlign?: string;
  headerAlign?: string;
  width?: string;
  color?: string;
  background?: string;
  order?: boolean;
  hidden?: boolean;
  render?: (data: T) => ReactNode;
  mask?: (value: any) => any;
};

export type FilterOption = {
  id: string | number;
  label: string;
}

export enum ColumnType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  TIME = 'time',
  IDENTIFIER = 'identifier'
}

export enum TextFilterOperator {
  LIKE = 'LIKE',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  EQUAL = 'EQUAL',
}

export enum IdentifierFilterOperator {
  ONLY = 'ONLY',
  SOME = 'SOME'
}

export enum NumberFilterOperator {
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_OR_EQUAL_THAN = 'GREATER_OR_EQUAL_THAN',
  LESS_THAN = 'LESS_THAN',
  LESS_OR_EQUAL_THAN = 'LESS_OR_EQUAL_THAN',
  BETWEEN = 'BETWEEN'
}

export enum DateFilterOperator {
  EQUAL = 'EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_OR_EQUAL_THAN = 'GREATER_OR_EQUAL_THAN',
  LESS_THAN = 'LESS_THAN',
  LESS_OR_EQUAL_THAN = 'LESS_OR_EQUAL_THAN',
  DATE_RANGE = 'DATE_RANGE',
  PARTIAL_DATE_RANGE = 'PARTIAL_DATE_RANGE',
}

export type ValueType = string | number | Date | boolean | FilterOption;

export interface ColumnFilter<ValueType> {
  column?: string;
  operator?: string;
  values?: { value: ValueType }[];
  hidden?: boolean;
}

export interface ColumnFilterNode<ValueType> {
  field?: string;
  operator?: string;
  values?:  ValueType [];
  negate?: boolean;
}