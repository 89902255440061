import {
    StyledAddFilterContainer,
    StyledAddFilterOptionsContainer
} from "../../styled-components/StyledAddFilterContainer";
import {
    StyledFormTableAddFilter,
    StyledFormTableFilterAddInput,
    StyledFormTableFilterInputs, StyledFormTableFilterInputWrapper
} from "../../styled-components/StyledFormTableFilter";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {StyledApplyFilterIcon, StyledCancelFilterIcon} from "../../styled-components/StyledFilterIcons";
import {useFieldArray, useForm} from "react-hook-form";
import {
    ColumnFilter, DateFilterOperator,
    IdentifierFilterOperator, NumberFilterOperator,
    TableColumn,
    TextFilterOperator,
    ValueType
} from "../../domain/columns.model";
import {Input, Select} from "../../../Inputs";
import {useIntl} from "react-intl";
import {useEffect} from "react";
import translate from "../../../../../language/translate";
import {getInputTypeFromColumnType} from "../../tableUtils/columnUtils";
import {InputType} from "../../../Inputs/Input";
import {Checkbox} from "../../../Inputs/Checkbox";

interface AddFilterProps<T> {
    show: boolean;
    columns: TableColumn<T>[];
    filters?: ColumnFilter<ValueType>[];
    onApply: (data:  ColumnFilter<ValueType>) => void;
    onCancel: () => void;
}

export function AddFilter<T>({ show, columns, filters, onApply, onCancel }: AddFilterProps<T>) {
    const intl = useIntl();
    const UNDEFINED_COLUMN = "-1";

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
        resetField
    } = useForm<ColumnFilter<ValueType>>({
        defaultValues: {
            column: UNDEFINED_COLUMN,
            operator: undefined,
            values:  [{ value: ""}]
        },
        mode: 'onSubmit'
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "values"
    });

    const selectedColumn = columns.find((col:TableColumn<T>) => col.field === watch('column'));
    const operators = selectedColumn?.filterOperators ? selectedColumn?.filterOperators?.map((filterOperator) => {
        return {
            label: intl.formatMessage({ id: filterOperator }),
            value: filterOperator
        };
    }) : [];
    const selectedOperator = watch('operator');
    const watchValues = watch("values");
    const valuesList = fields.map((field, index) => {
        return {
            ...field,
            ...watchValues?.[index]
        };
    });

    const onSubmit = (data: ColumnFilter<ValueType>) => {
        if(selectedOperator === IdentifierFilterOperator.ONLY) {
            // eslint-disable-next-line eqeqeq
            const dataValue = selectedColumn?.filterOptions?.find(option => option.id == data?.values?.[0].value);
            if(dataValue){
                data.values = [{ value: dataValue }];
            } else {
                data.values = [];
            }
        }
        if(selectedOperator === IdentifierFilterOperator.SOME){
            const indexList = data?.values?.map((dat, index) => {
                if(dat !== undefined && dat.value === true){
                    return index;
                }
                return undefined;
            }).filter((dat) => dat !== undefined);
            const dataValues = indexList?.map((indexData) => {
                if(indexData !== undefined){
                    return { value: selectedColumn?.filterOptions?.[indexData] };
                }
                return undefined;
            }).filter((dat) => dat !== undefined)
            if(dataValues){
                // @ts-ignore
                data.values = [...dataValues];
            }
        }
        onApply(data);
        reset();
        resetField('values', {
            defaultValue: [{ value: ""}]
        });
    }

    const handleCancel = () => {
        reset();
        resetField('values', {
            defaultValue: [{ value: ""}]
        });
        onCancel();
    }

    useEffect(() => {
        if(selectedOperator === NumberFilterOperator.BETWEEN || selectedOperator === DateFilterOperator.DATE_RANGE){
            resetField('values', {
                defaultValue: [{ value: ""}, { value: ""}]
            })
        } else {
            resetField('values', {
                defaultValue: [{ value: ""}]
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOperator])

    return (
        <StyledAddFilterContainer show={show}>
            <StyledFormTableAddFilter onSubmit={handleSubmit(onSubmit)}>
                <StyledApplyFilterIcon type='submit' ><DoneIcon /></StyledApplyFilterIcon>
                <StyledCancelFilterIcon type='button' onClick={handleCancel}><CloseIcon /></StyledCancelFilterIcon>
                <StyledFormTableFilterInputs>
                    <Select
                        register={register("column",{
                            required: true,
                            validate: (value) => {
                                if(value === UNDEFINED_COLUMN){
                                    return intl.formatMessage({ id: 'column is required' })
                                }
                                return true;
                            }
                        })}
                        name="column"
                        errors={errors}
                        nullValue={UNDEFINED_COLUMN}
                        label={intl.formatMessage({ id: "column" })}
                        options={columns.map((col) => {
                            return {
                                label: col.label,
                                value: col.field,
                            };
                        })}
                        width="100%"
                    />
                    <Select
                        register={register("operator", {
                            required: true,
                            validate: (value) => {
                                if(value === UNDEFINED_COLUMN){
                                    return intl.formatMessage({ id: 'operator is required' })
                                }
                                return true;
                            }
                        })}
                        name="operator"
                        errors={errors}
                        nullValue={UNDEFINED_COLUMN}
                        label={intl.formatMessage({ id: "operator" })}
                        options={operators}
                        width="100%"
                    />
                    {selectedOperator !== IdentifierFilterOperator.ONLY && selectedOperator !== IdentifierFilterOperator.SOME ?
                        valuesList.map((field, index) => {
                        return (
                            <StyledFormTableFilterInputWrapper key={`values.${index}.value`}>
                                <Input
                                    register={register(`values.${index}.value` as const, {
                                        validate: (value, formValues) => {
                                            return formValues?.values?.[index].value !== "";
                                        }
                                    })}
                                    name='values'
                                    errors={errors}
                                    label={`${intl.formatMessage({
                                        id: "value",
                                    })} ${valuesList.length > 1 ? index : ''}`}
                                    type={getInputTypeFromColumnType(selectedColumn?.type)}
                                    width="100%"
                                />
                                {
                                    index !== 0 && selectedOperator !== NumberFilterOperator.BETWEEN && selectedOperator !== DateFilterOperator.DATE_RANGE ?
                                        <CloseIcon onClick={() => remove(index)} />
                                        : null
                                }
                            </StyledFormTableFilterInputWrapper>
                        )
                    })
                        : null
                    }
                    {
                        selectedOperator === TextFilterOperator.LIKE ?
                            <StyledFormTableFilterAddInput
                                type="button"
                                onClick={() =>
                                    append({
                                        value: ""
                                    })
                                }
                            >
                                {translate('add value')}
                            </StyledFormTableFilterAddInput>
                            : null
                    }
                    {
                        selectedOperator === IdentifierFilterOperator.ONLY && selectedColumn?.filterOptions ?
                            <Select
                                register={register("values.0.value" as const, {
                                    validate: (value, formValues) => {
                                        return formValues?.values?.[0].value !== "";
                                    }
                                })}
                                name="values"
                                errors={errors}
                                nullValue={''}
                                label={intl.formatMessage({ id: "value" })}
                                options={selectedColumn.filterOptions.map(filterOption => ({
                                    value: filterOption.id,
                                    label: filterOption.label
                                }))}
                                width="100%"
                            />
                            : null
                    }
                    {
                        selectedOperator === IdentifierFilterOperator.SOME && selectedColumn?.filterOptions ?
                            <StyledAddFilterOptionsContainer>
                                {
                                    selectedColumn.filterOptions.map((filterOption, index) => (
                                        <Checkbox
                                            key={`${selectedColumn.field}_filteroption_${index}`}
                                            register={register(`values.${index}.value` as const)}
                                            id={`${filterOption.id}`}
                                            name='values'
                                            label={filterOption.label}
                                            type={InputType.CHECKBOX}
                                        />
                                    ))
                                }
                            </StyledAddFilterOptionsContainer>
                            : null
                    }
                </StyledFormTableFilterInputs>
            </StyledFormTableAddFilter>
        </StyledAddFilterContainer>
    )
}