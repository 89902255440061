import {ColumnFilter, ColumnFilterNode, DateFilterOperator, IdentifierFilterOperator} from "../domain/columns.model";
import {dateToString} from "../../../../utilities/dateUtils";

export function createAdapterToFilterBackend<ValueType>(
    filter: ColumnFilter<ValueType>,
): ColumnFilterNode<ValueType> {
    const { column, operator, values } = filter;
    const transformedObject: ColumnFilterNode<ValueType> = {};

    if (column !== undefined) {
        transformedObject.field = column;
    }

    if (operator !== undefined) {
        transformedObject.operator = operator;
    }

    if (
        operator !== undefined &&
        Object.values(DateFilterOperator).includes(operator as DateFilterOperator)
    ) {
        transformedObject.values = values?.map((item) =>
            dateToString(item.value as Date) as ValueType
        );
        return transformedObject;
    }

    if (values !== undefined && values.length > 0) {
        if (operator === IdentifierFilterOperator.SOME || operator === IdentifierFilterOperator.ONLY) {
            // @ts-ignore
            transformedObject.values = values.map((item) => item.value.id.toString());
        } else {
            transformedObject.values = values.map((item) => item.value);
        }
    }
    return transformedObject;
}
