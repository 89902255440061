import translate from "../../../../../language/translate";
import { SidebarNavLogout } from "../styled-components/SidebarNavLogout";
import { StyledDropDownMenu } from "../styled-components/StyledDropDownMenu";
import { FC } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { useIntl } from "react-intl";
import DropMenuItem from "./DropMenuItem";
import { useLogoutMutation } from "../../../../../auth/services/authApi";

interface DropMenuTypes {
  openDropMenu?: boolean;
  dropdownToggle: () => void;
}

const DropMenu: FC<DropMenuTypes> = ({ openDropMenu, dropdownToggle }) => {
  const [logout] = useLogoutMutation();
  const intl = useIntl();
  const handleLogout = () => {
    logout();
  };
  return (
    <StyledDropDownMenu openDropMenu={openDropMenu}>
      <DropMenuItem
        label={intl.formatMessage({
          id: "user profile",
        })}
        link="/user-profile"
        dropdownToggle={dropdownToggle}
      />
      <DropMenuItem
        label={intl.formatMessage({
          id: "settings",
        })}
        link="/settings"
        dropdownToggle={dropdownToggle}
      />
      <SidebarNavLogout onClick={handleLogout}>
        <LogoutIcon />
        <span>{translate("exit")}</span>
      </SidebarNavLogout>
    </StyledDropDownMenu>
  );
};

export default DropMenu;
